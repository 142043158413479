import { gql, type TypedDocumentNode } from '@apollo/client';
import { type LandingQueryQuery } from '@apps/www/src/__generated__/graphql';

const LandingQuery: TypedDocumentNode<LandingQueryQuery> = gql`
	query LandingQuery {
		landing {
			items {
				_id
				asset {
					_id
					image {
						thumbnail
						ratio
					}
					colors {
						color
					}
				}
			}
		}
	}
`;

export default LandingQuery;
