/**
 * Only returns true if user is member of a team with an active subscription. Use this to display the team menu or gate the /team/ page.
 */

import useAuthActiveSubscriptionTiers from '@apps/www/src/www/hooks/useAuthActiveSubscriptionTiers';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import subscriptionTiersHasSubscriptionTierPrivileges from '@pkgs/shared/helpers/subscriptionTiersHasSubscriptionTierPrivileges';

export default function useAuthHasTeamPrivileges() {
	const tiers = useAuthActiveSubscriptionTiers();

	return subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.TEAM);
}
