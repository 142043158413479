import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import appendQueryParamsToURL from '@pkgs/shared/helpers/appendQueryParamsToURL';
import { useRouter } from 'next/router';
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import SVRedirect from './SVRedirect';

// TODO: Instead of this can we make versions of the hooks useAuthUser or useAuthSettings that
// throw an error or redirect when not logged in? Then we can remove unecessary null checks on the result
// on many components.

const SVWithRequiredLogin = <P,>(WrappedComponent: React.ComponentType<P>) => {
	const SVWithRequiredLogin = (props: P) => {
		const router = useRouter();
		const isLoggedIn = useIsLoggedIn();

		if (!isLoggedIn) {
			const next = router.asPath !== '/' ? router.asPath : undefined;

			return <SVRedirect to={appendQueryParamsToURL('/login/', next ? { next } : {})} replace={true} />;
		}

		return <WrappedComponent {...(props as P & JSX.IntrinsicAttributes)} />;
	};

	SVWithRequiredLogin.displayName = wrapDisplayName(WrappedComponent, 'SVWithRequiredLogin');

	return SVWithRequiredLogin;
};

export default SVWithRequiredLogin;
