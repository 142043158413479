import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type FeedItemsQueryQuery,
	type FeedItemsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const FeedItemsQuery: TypedDocumentNode<FeedItemsQueryQuery, FeedItemsQueryQueryVariables> = gql`
	${ItemFragment}

	query FeedItemsQuery($cursor: String) {
		feedItems(cursor: $cursor) {
			items {
				...ItemFragment
			}
			pageInfo {
				nextCursor
			}
		}
	}
`;

export default FeedItemsQuery;
