import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import SVFeed from '@apps/www/src/www/pages/SVFeed';
import SVLandingPage from '@apps/www/src/www/pages/SVLandingPage';

const SVIndexPage = () => {
	const isLoggedIn = useIsLoggedIn();

	if (isLoggedIn) {
		return <SVFeed />;
	}

	return <SVLandingPage />;
};

SVIndexPage.withNavHidden = ({ isLoggedIn }) => !isLoggedIn;

export default SVIndexPage;
