import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVGrid from '@pkgs/shared-client/components/SVGrid';
import SVGridItem from '@pkgs/shared-client/components/SVGridItem';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { unit } from '@pkgs/shared-client/styles/mixins';
import { VIEWPORTS, type GridColumnsConfigID } from '@pkgs/shared/constants';
import clsx from 'clsx';

// const ENABLE_MOUSE_ROTATION = false;
// const HALF_PI = Math.PI * 0.5;

// TODO: Context API?
/* class MouseManager {
	enabled = ENABLE_MOUSE_ROTATION && !isTouch();
	items = [];
	mousePosition = isSSR
		? { x: 0, y: 0 }
		: {
				x: Math.round(window.innerWidth * 0.5),
				y: Math.round(window.innerHeight * 0.5),
		  };
	windowSize = isSSR ? { x: 0, y: 0 } : { x: window.innerWidth, y: window.innerHeight };

	measure1 = null; // max window width/height
	measure2 = null; // 600 / measure1

	add(element) {
		if (!this.enabled || !element) {
			return;
		}

		const newItem = {
			element,
			metrics: {},
		};

		this.items.push(newItem);

		// element.parentElement.style.perspective = '500px';

		setTimeout(() => {
			this._updateItemMetrics(newItem);
			this._updateItemLayout(newItem);
		}, 200);
	}

	remove(element) {
		this.items = this.items.filter((item) => item.element !== element);
	}

	register() {
		if (!this.enabled) {
			return;
		}

		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('resize', this.handleResize);

		this.handleResize();
	}

	unregister() {
		if (!this.enabled) {
			return;
		}

		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('resize', this.handleResize);
	}

	handleMouseMove = (event) => {
		this.mousePosition.x = event.clientX;
		this.mousePosition.y = event.clientY;

		this.items.forEach(this._updateItemLayout);
	};

	handleResize = () => {
		this.measure1 = Math.max(window.innerWidth, window.innerHeight);
		this.measure2 = 400 / this.measure1;

		this.items.forEach(this._updateItemMetrics);
		this.items.forEach(this._updateItemLayout);
	};

	_updateItemMetrics = (item) => {
		const { element, metrics } = item;
		const rect = element.getBoundingClientRect();

		metrics.x = rect.left + rect.width * 0.5;
		metrics.y = rect.top + rect.height * 0.5;
	};

	_updateItemLayout = (item) => {
		if (!this.measure1) {
			return;
		}

		const distanceX = (this.mousePosition.x - item.metrics.x) / this.measure1;
		const distanceY = (item.metrics.y - this.mousePosition.y) / this.measure1;

		const distX = Math.atan2(distanceX, this.measure2) / HALF_PI;
		const distY = Math.atan2(distanceY, this.measure2) / HALF_PI;

		if (item.element && item.element.style) {
			item.element.style.transform = `rotateX(${distY * 7}deg) rotateY(${distX * 7}deg)`;
		}
	};
}

const MANAGER = new MouseManager();

class _Item extends React.Component {
	// element = null;

	// componentWillUnmount() {
	// 	MANAGER.remove(this.element);
	// 	this.element = null;
	// }

	_setRef = (_el) => {
		// if (this.element) {
		// 	MANAGER.remove(this.element);
		// }
		// // eslint-disable-next-line react/no-find-dom-node
		// this.element = ReactDOM.findDOMNode(el);
		// MANAGER.add(this.element);
	};

	render() {
		return <SVGridItem ref={this._setRef} {...this.props} />;
	}
} */

// TODO: Only load image if visible. Use useIntersection from nextjs?

type Props = {
	items: ItemFragmentFragment[];
};

const SVLandingGrid = ({ items }: Props) => {
	const viewportName = useViewportName();

	// useMount(() => {
	// 	MANAGER.register();
	// });

	// useUnmount(() => {
	// 	MANAGER.unregister();
	// });

	const handleGetColumnClassName = useEventCallback((columnIndex: number) => {
		return clsx(
			'landing-column',
			columnIndex % 2 === 0 ? 'animate-sliding-up' : 'animate-sliding-down',
		);
	});

	const handleGetColumnStyle = useEventCallback((columnIndex: number) => {
		return {
			animationDelay: unit(columnIndex * 0.15, 's'),
			// animationDelay: unit(Math.random() * 1.5, 's'),
		};
	});

	const viewportConfig = viewportName && VIEWPORTS[viewportName];
	const columnsConfigID = (viewportConfig?.columns || 8) as GridColumnsConfigID;
	const spacingConfigID = 8;

	return (
		<div className="bg-background pointer-events-none fixed inset-0 h-full w-full overflow-hidden">
			{/* columnsConfigID can be undefined if viewportName is not ready yet (on server and client first render) */}
			{columnsConfigID ? (
				<SVGrid
					className="scale-125"
					autoPlayGIFs={false}
					columnsConfigID={columnsConfigID}
					isStatic={true}
					items={items}
					keyExtractor={(item) => item._id}
					spacingConfigID={spacingConfigID}
					getColumnClassName={handleGetColumnClassName}
					getColumnStyle={handleGetColumnStyle}
					renderItem={(item, props) => (
						<SVGridItem
							{...SVGridItem.itemToProps(item)}
							{...props}
							forceThumbnail={true}
						/>
					)}
				/>
			) : null}
			<div className="bg-background absolute top-0 h-full w-full bg-opacity-75" />
		</div>
	);
};

export default SVLandingGrid;
