import { useQuery } from '@apollo/client';
import SVLandingGrid from '@apps/www/src/www/components/SVLandingGrid';
import LandingQuery from '@apps/www/src/www/queries/LandingQuery';

const SVLandingGridContainer = () => {
	// No SSR to not block the homepage
	const { data, loading, error } = useQuery(LandingQuery, { ssr: false });

	if (loading || !data || !data.landing || error) {
		return null;
	}

	const items = data.landing.items;

	if (!items || !items.length) {
		return null;
	}

	// @ts-ignore Fix this type down to the SVGrid components
	return <SVLandingGrid items={items} />;
};

export default SVLandingGridContainer;
