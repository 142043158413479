import SVTeamFeedPage from '@apps/www/src/pages/team.page';
import SVGridContainer from '@apps/www/src/www/containers/SVGridContainer';
import SVWithRequiredLogin from '@apps/www/src/www/containers/SVWithRequiredLogin';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import usePaginatedQuery from '@apps/www/src/www/hooks/usePaginatedQuery';
import FeedItemsQuery from '@apps/www/src/www/queries/FeedItemsQuery';
import SVGrid from '@pkgs/shared-client/components/SVGrid';
import { usePlausible } from 'next-plausible';
import { useMount } from 'react-use';

// forceRegularFeed is used in FeedPage to force the default feed query to be used
const _SVFeed = ({ forceRegularFeed }: { forceRegularFeed?: boolean }) => {
	const plausible = usePlausible();
	const team = useAuthTeam();

	// No need to check for errors here as this is the only page the user can load to access navigation
	// No SSR here to speed up first load, feed is usually a slow query.
	const { items, loading, paginate } = usePaginatedQuery(FeedItemsQuery, {
		ssr: false,
		errorPolicy: 'ignore',
	});

	useMount(() => plausible('page_home_logged_in'));

	if (!(forceRegularFeed || !team?.teamFeedAsHomepage)) {
		return <SVTeamFeedPage />;
	}

	return (
		<SVGridContainer
			sourceType={SVGrid.SOURCE_TYPES.FEED}
			items={items}
			isLoading={loading}
			onPaginate={paginate}
		/>
	);
};

const SVFeed = SVWithRequiredLogin(_SVFeed);

export default SVFeed;
