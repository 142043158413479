import SVLandingGridContainer from '@apps/www/src/www/containers/SVLandingGridContainer';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVLink from '@pkgs/shared-client/components/SVLink';
import LogoSVG from '@pkgs/shared-client/img/logo-inlined.svg';
import { usePlausible } from 'next-plausible';
import { useMount } from 'react-use';

const SVLandingPage = () => {
	const plausible = usePlausible();

	useMount(() => plausible('page_home_unlogged'));

	return (
		<div className="theme-dark flex min-h-screen w-full justify-between">
			<SVLandingGridContainer />
			<div className="relative box-border flex flex-grow flex-col">
				<div className="-smp:px-5 relative box-border flex flex-grow flex-col justify-between space-y-20 p-10">
					<div className="flex justify-center">
						<LogoSVG className="text-primary h-11 w-11" />
					</div>
					<div className="flex flex-col justify-center text-center">
						<h1 className="type-huge -smp:mb-4 mb-6">Made for Creatives</h1>
						<p className="type-subtitle -smp:mb-8 mb-16 inline-block">
							Browse and save curated inspiration from all over the&nbsp;world.
						</p>
						<div className="flex-center flex space-x-6">
							<SVButton
								use={SVButtonUSES.GRADIENT}
								Component={SVLink}
								to="/login/"
								title="Login"
							>
								Get inspired
							</SVButton>
						</div>
					</div>
					<SVLandingPageFooter />
				</div>
			</div>
		</div>
	);
};

export default SVLandingPage;
